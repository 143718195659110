import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import AgeCalc from './pages/AgeCalc/AgeCalc';

const App: React.FC = () => {
  useEffect(() => {
    const appContainer = document.getElementById('appContainer');
    if (appContainer && window.innerHeight > 800) {
      appContainer?.classList.add('h-screen');
    } else {
      appContainer?.classList.add('h-screen');
    }
  }, []);

  return (
    <div id='appContainer'>
      <AgeCalc />
      <Toaster />
    </div>
  );
};

export default App;
