import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import Logo from '../../assets/calc.png';

export default function AgeCalc() {
          const [birthDate, setBirthDate] = useState<string>('');
          const [year, setYear] = useState<number | null>(null);
          const [month, setMonth] = useState<number | null>(null);
          const [months, setMonths] = useState<number | null>(null);
          const [day, setDay] = useState<number | null>(null);
          const [days, setDays] = useState<number | null>(null);
          const [weeks, setWeeks] = useState<number | null>(null);
          const [nextBD, setNextBD] = useState<string>('');
          const [error, setError] = useState('');

          const [showDOB, setShowDOB] = useState<string>('');
          const [inputDOB, setInputDOB] = useState<string>('');

          const validateDate = (dateString: string) => {
                    try {
                              const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                              const [year, month, day] = dateString.split('-').map(Number);
                              if (month < 1 || month > 12 || day < 1 || year < 1 || year <= 1900) {
                                        return false;
                              }

                              if (month === 2) {
                                        if (day >= 30) {
                                                  return false;
                                        } else if (day === 29) {
                                                  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                                                            return true;
                                                  } else {
                                                            return false;
                                                  }
                                        }
                              }

                              if (day > 31 && [1, 3, 5, 7, 8, 10, 12].includes(month)) {
                                        return false;
                              }

                              if (day === 31 && [4, 6, 9, 11].includes(month)) {
                                        return false;
                              }

                              if (day > 30 && [4, 6, 9, 11].includes(month)) {
                                        return false;
                              }

                              if (!dateRegex.test(dateString)) {
                                        return false;
                              }

                              return true;
                    } catch (error) {
                              return false;
                    }
          };

          const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (value.length > 10) {
                              return;
                    }
                    const formattedDate = formatDateString(value);
                    const dateValue = value.split('-');
                    const year = dateValue[2];
                    const month = dateValue[1];
                    const day = dateValue[0];
                    const finalValue = `${year}-${month}-${day}`;
                    setInputDOB(formattedDate);

                    if (validateDate(finalValue) === false) {
                              setError('Please enter date in DD-MM-YYYY format.');
                              return;
                    } else if (finalValue > new Date().toISOString().split('T')[0]) {
                              setError('Please don\'t enter a future date.');
                              return;
                    } else {
                              setBirthDate(finalValue);
                              setError('');
                    }
          };

          const formatDateString = (inputDate: string) => {
                    const cleanedInput = inputDate.replace(/[^0-9]/g, '');
                    const day = cleanedInput.slice(0, 2);
                    const month = cleanedInput.slice(2, 4);
                    const year = cleanedInput.slice(4, 8);

                    let formattedDate = '';
                    if (day) formattedDate += day;
                    if (month) formattedDate += '-' + month;
                    if (year) formattedDate += '-' + year;

                    if (formattedDate.length > 10) {
                              return formattedDate;
                    }

                    return formattedDate;
          };

          const calculateAge = () => {
                    if (birthDate === '' || inputDOB === '') {
                              toast.error('Please enter your birth date.');
                              return;
                    }

                    const birthDateArr = birthDate.split('-');
                    const d1 = parseInt(birthDateArr[2]);
                    const m1 = parseInt(birthDateArr[1]);
                    const y1 = parseInt(birthDateArr[0]);

                    let date = new Date();
                    let d2 = date.getDate();
                    let m2 = date.getMonth() + 1;
                    let y2 = date.getFullYear();

                    const month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                    if (d1 > d2) {
                              d2 = d2 + month[m2 - 1];
                              m2 = m2 - 1;
                    }
                    if (m1 > m2) {
                              m2 = m2 + 12;
                              y2 = y2 - 1;
                    }
                    const d = d2 - d1;
                    const m = m2 - m1;
                    const y = y2 - y1;

                    setYear(y);
                    setMonth(m);
                    setDay(d);
                    handleMonths();
                    handleWeeks();
                    handleDays();
                    handleNextBD();
                    setInputDOB('');
                    setShowDOB(moment(birthDate).format('Do MMMM YYYY'));
          };

          const handleMonths = () => {
                    const currentDate = new Date();
                    const inputDate = new Date(birthDate);
                    const yearDiff = currentDate.getFullYear() - inputDate.getFullYear();
                    const monthDiff = currentDate.getMonth() - inputDate.getMonth();
                    const dayDiff = currentDate.getDate() - inputDate.getDate();

                    let totalMonths = yearDiff * 12 + monthDiff;

                    if (dayDiff < 0) {
                              totalMonths--;
                    }
                    setMonths(totalMonths);
          }

          const handleNextBD = () => {
                    const currentDate = new Date();
                    const inputDate = new Date(birthDate);

                    inputDate.setFullYear(currentDate.getFullYear());

                    if (inputDate < currentDate) {
                              inputDate.setFullYear(currentDate.getFullYear() + 1);
                    }

                    setNextBD(moment(inputDate).format('Do MMMM YYYY'));
          }

          const handleDays = () => {
                    let days = null;
                    const currentDate = new Date();
                    const inputDate = new Date(birthDate);
                    const timeDiff = currentDate.getTime() - inputDate.getTime();
                    const calculatedDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;
                    days = calculatedDays;
                    setDays(days);
          }

          const handleWeeks = () => {
                    const currentDate = new Date();
                    const inputDate = new Date(birthDate);
                    const timeDiff = currentDate.getTime() - inputDate.getTime();
                    const calculatedWeeks = Math.ceil(timeDiff / (1000 * 3600 * 24 * 7)) - 1;
                    setWeeks(calculatedWeeks);
          };

          const handleClear = () => {
                    setBirthDate('');
                    const birthDateInput = document.getElementById('birthDate') as HTMLInputElement;
                    birthDateInput.value = '';
                    setShowDOB('');
                    setYear(null);
                    setMonth(null);
                    setDay(null);
          };

          return (
                    <div className="w-full px-4 md:px-0 md:w-2/3 lg:w-1/3 mx-auto py-2 md:py-16">
                              <h1 className="text-2xl font-bold text-center mb-8 my-4 select-none">
                                        <img src={Logo} alt="KToDos" className='w-8 inline-block mr-2' />
                                        AgeCalc - <span className='text-xs'>an simple age calculator app</span>
                              </h1>
                              <div className='glass p-2 md:p-5 rounded-xl'>
                                        <div className='flex w-full mx-auto'>
                                                  <input
                                                            type="text"
                                                            id='birthDate'
                                                            className="input focus:outline-none glass placeholder:text-xs placeholder:md:text-[16px] pl-5 p-2 py-8 flex-grow rounded-xl rounded-tr-none rounded-br-none w-full"
                                                            placeholder="Type your date of birth (DD-MM-YYYY)"
                                                            value={inputDOB}
                                                            autoComplete='off'
                                                            onChange={handleInputChange}
                                                            onKeyDown={(e) => {
                                                                      if (e.key === 'Enter' && error === '') {
                                                                                calculateAge();
                                                                      }
                                                            }}

                                                  />
                                                  <button
                                                            className={`glass bg-gradient-to-tl md:bg-gradient-to-br to-[#EEAECA] from-[#94BBE9] text-white uppercase text-xs md:text-[14px] font-semibold py-2 px-4 rounded-xl rounded-tl-none rounded-bl-none ${error !== '' ? 'text-gray-400' : 'text-black'}`}
                                                            onClick={calculateAge}
                                                  >
                                                            Calculate
                                                  </button>
                                        </div>
                                        {error && <p className='text-red-600 text-xs pl-2 mt-2'>{error}</p>}
                                        {year !== null && (
                                                  <div className='w-full px-4 md:px-0 flex flex-col justify-center items-center gap-5 glass mt-5 rounded-xl py-10 select-none'>
                                                            <p className='md:text-xl text-center'>
                                                                      Your Date of Birth is <span className='font-bold'>{showDOB}</span>.
                                                            </p>
                                                            <p className="md:text-xl text-center">
                                                                      Your age is <span className='font-bold'>{year}</span> years, <span className='font-bold'>{month}</span> months and <span className='font-bold'>{day}</span> days.
                                                                      {months !== null && (
                                                                                <div className='flex flex-col gap-3 mt-3'>
                                                                                          <span className='md:text-xl'>
                                                                                                    Or <span className='font-bold'>{months}</span> months.
                                                                                          </span>
                                                                                          <span className='md:text-xl'>
                                                                                                    Or <span className='font-bold'>{weeks}</span> weeks.
                                                                                          </span>
                                                                                          <span className='md:text-xl'>
                                                                                                    Or <span className='font-bold'>{days}</span> days.
                                                                                          </span>
                                                                                          <span className='md:text-xl'>
                                                                                                    Next birthday: <span className='font-bold'>{nextBD}</span>
                                                                                          </span>
                                                                                </div>
                                                                      )}
                                                            </p>
                                                            <button className='glass bg-gradient-to-br md:bg-gradient-to-tl to-[#EEAECA] from-[#94BBE9] text-white px-4 py-2 rounded-xl uppercase font-semibold text-xs md:text-[14px]'
                                                                      onClick={handleClear}
                                                            >
                                                                      Clear
                                                            </button>
                                                  </div>
                                        )}
                              </div>
                    </div>
          )
}
